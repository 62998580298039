<template>
  <div id="user-table-component">

    <div class="d-flex justify-end mb-2">
      <memlist-icon-button
        icon="menu-icon flaticon2-plus"
        :text="$t('USER.CREATE')"
        variant="primary"
        @click="new_user_clicked"
      />
    </div>

    <b-table
      id="user-table"
      style="width: 100%; table-layout: fixed;"
      class="table-striped"
      :fields="fields"
      :items="items"
      head-variant="light"
      ref="userTable"
      :tbody-tr-class="row_class"
    >
      <template #cell(actions)="data">
        <div class='d-flex justify-content-end'>
          <span>
            <button class="btn btn-icon btn-light btn-sm mr-2 edit-action" @click.prevent="edit_user_clicked(data.item)">
              <span class="svg-icon text-primary">
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
              </span>
            </button>
            <button class="btn btn-icon btn-light btn-sm" @click.prevent="delete_user_clicked(data.item)">
              <span class="svg-icon text-primary">
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
              </span>
            </button>
          </span>
        </div>
      </template>
    </b-table>


  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'user-table',
  components: {

  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'pages']),

  },
  props: ['items'],
  emits: ['selected', 'delete', 'create'],
  async mounted() {

  },
  methods: {

    row_class(item, type) {
      if (!item || type !== 'row') return;

      return item.group_id === null ? 'table-danger' : '';
    },

    edit_user_clicked(item) {
      this.$emit('selected', item);
    },

    delete_user_clicked(item) {
      this.$emit('delete', item.user_id);
    },

    new_user_clicked() {
      this.$emit('create');
    },

    if_null_empty(value) {
      if (!value) {
        return '';
      }

      return value;
    },
  },
  data() {
    return {

      user: {},

      fields: [
        {
          key: 'user_id',
          label: this.$t('USER.ID'),
          sortable: false,
          mobile: false,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },
        {
          key: 'name',
          label: this.$t('USER.NAME'),
          sortable: false,
          mobile: true,
          tdClass: 'td-overflow-wide',
          thClass: 'td-overflow-wide',
          formatter: (_, __, item) => {
            return this.if_null_empty(item.firstname) + ' ' + this.if_null_empty(item.lastname) + ' ' + this.if_null_empty(item.email);
          }
        },
        {
          key: 'group_name',
          label:  this.$t('USER.GROUP'),
          sortable: false,
          mobile: false,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },
        {
          key: 'actions',
          label: this.$t('COMMON.ACTIONS'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short',
          thClass: 'td-short'

        },
      ],
    };
  },
  watch: {


  },

};
</script>
<style lang="scss" scoped>


:deep .td-short {
  width: 32px;
  overflow: hidden;
}

:deep .td-short2 {
  width: 56px;
  overflow: hidden;
}

:deep .td-overflow-wide {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  width: 50%;
}

:deep .v-treeview-node__root:hover {
  cursor: pointer;
}

.tree-panel {
  background-color: white;
}

:deep td {
  vertical-align: middle !important;
}

:deep .v-simple-checkbox {
  float: left;
  width: 22px;
  margin-left: 8px;
}

:deep .v-input__slot label {
  font-size: 12px;
}

:deep .v-data-table__wrapper tr:first-child {
  height: 66px;
  background-color: rgb(239, 239, 239) !important;
}

</style>
