<template>
  <div>
    <b-table id="table" class="table-striped mh-100" :fields="headers" :items="terms" head-variant="light" sticky-header hover>
        <template #cell(is_active)="data">
          <div class="d-flex align-items-center">
            <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
              <input type="checkbox" name="" v-model="data.item.is_active" v-on:change="active_changed(data.item)"/>
              <span></span>
            </label>
            <span class="ml-3 cursor-pointer"></span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class='d-flex justify-content-end'>
            <span>
              <button class="btn btn-icon btn-light btn-sm mr-4 edit-action" @click.prevent="edit_term_clicked(data.item)">
                <span class="svg-icon text-primary">
                  <inline-svg src="/assets/svg/Write.svg"></inline-svg>
                </span>
              </button>
              <button class="btn btn-icon btn-light btn-sm mr-4" @click.prevent="delete_term_clicked(data.item)">
                <span class="svg-icon text-primary">
                  <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
                </span>
              </button>
            </span>
          </div>
        </template>
    </b-table>
  </div>
</template>

<script>

import dayjs from 'dayjs';

export default {

  name: 'TermsTable',

  mixins: [],

  components: {},

  props: {
    terms: {
      type: Array,
      required: true
    },
  },
  emits: ['edit_term', 'delete_term', 'active_changed'],

  computed: {},

  watch: {},

  methods: {

    edit_term_clicked(term) {
      this.$emit('edit_term', term);
    },

    delete_term_clicked(term) {
      this.$emit('delete_term', term);
    },

    active_changed(term) {
      this.$emit('active_changed', term);
    },

  },

  mounted() {},

  data() {
    return {
      headers: [
        {
          key: 'is_active',
          show: true,
          sortable: false,
          label: this.$t('PAGES.TERMS.ACTIVE'),
          thClass: 'pl-7',
          tdClass: 'pl-7 vertical-center'
        },
        {
          key: 'name',
          show: true,
          sortable: true,
          label: this.$t('PAGES.TERMS.NAME'),
          thClass: 'pl-7',
          tdClass: 'pl-7 vertical-center'
        },
        {
          key: 'created_at',
          show: true,
          sortable: true,
          label: this.$t('PAGES.TERMS.CREATED_AT'),
          thClass: 'pl-7',
          tdClass: 'pl-7 vertical-center',
          formatter: (_, __, item) => {
            return `${dayjs(item.created_at).format('YYYY-MM-DD')}`;
          },
        },
        {
          key: 'actions',
          label: this.$t('COMMON.ACTIONS'),
          show: true,
          sortable: false,
          thClass: 'w-100',
        }
      ]
    };
  }
};

</script>
