<template>
  <div id="group-table-component">

    <b-row class="justify-content-end my-4 mx-1">
      <b-button class="font-weight-bolder font-size-sm" variant="primary" @click.prevent="new_group_clicked()">(+) {{$t('ACCESS.GROUP.CREATE')}}</b-button>
    </b-row>

    <b-table
      id="group-table"
      style="width: 100%; table-layout: fixed;"
      class="table-striped"
      :fields="fields"
      :items="items"
      head-variant="light"
      ref="groupTable"
    >
      <template #cell(actions)="data">
        <div class='d-flex justify-content-end'>
          <span>
            <button class="btn btn-icon btn-light btn-sm mr-4 edit-action" @click.prevent="edit_group_clicked(data.item)">
              <span class="svg-icon text-primary">
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
              </span>
            </button>
            <button class="btn btn-icon btn-light btn-sm mr-4" @click.prevent="delete_group_clicked(data.item)">
              <span class="svg-icon text-primary">
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
              </span>
            </button>
          </span>
        </div>
      </template>
    </b-table>


  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'group-table',
  components: {

  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'pages']),

  },
  props: ['items'],
  emits: ['selected', 'create', 'delete'],
  async mounted() {

  },
  methods: {

    edit_group_clicked(item) {
      this.$emit('selected', item);
    },

    delete_group_clicked(item) {
      this.$emit('delete', item.id);
    },

    new_group_clicked() {
      this.$emit('create');
    },


  },
  data() {
    return {

      group: {},

      fields: [
        {
          key: 'id',
          label: this.$t('ACCESS.GROUP.ID'),
          sortable: false,
          mobile: false,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },
        {
          key: 'name',
          label: this.$t('ACCESS.GROUP.NAME'),
          sortable: false,
          mobile: true,
          tdClass: 'td-overflow-wide ',
          thClass: 'td-overflow-wide '
        },
        {
          key: 'created_at',
          label:  this.$t('ACCESS.GROUP.CREATED_AT'),
          sortable: false,
          mobile: false,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },
        {
          key: 'actions',
          label: this.$t('COMMON.ACTIONS'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2'

        },
      ],
    };
  },
  watch: {


  },

};
</script>
<style lang="scss" scoped>

:deep .td-short {
  width: 32px;
  overflow: hidden;
}

:deep .td-short2 {
  width: 56px;
  overflow: hidden;
}

:deep .td-overflow-wide {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  width: 50%;
}

:deep .v-treeview-node__root:hover {
  cursor: pointer;
}

.tree-panel {
  background-color: white;
}

:deep .v-simple-checkbox {
  float: left;
  width: 22px;
  margin-left: 8px;
}

:deep .v-input__slot label {
  font-size: 12px;
}

:deep .v-data-table__wrapper tr:first-child {
  height: 66px;
  background-color: rgb(239, 239, 239) !important;
}

:deep td {
  vertical-align: middle !important;
}


</style>
